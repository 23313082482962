<!--
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2023-02-14 09:00:58
 * @LastEditors: dxl 1668645906@qq.com
 * @LastEditTime: 2023-09-15 14:10:32
-->
<template>
    <div class="visitorList">
        <div class="title-wrap">
            <div class="tag"></div>
            <div class="title">来访列表</div>
        </div>
        <div class="case-wrap">
            <div class="tabs-wrap">
                <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                    <el-tab-pane v-for="(item,index) in objectiveList" :key="index"
                        :label="item.name" :name="item.label">
                    </el-tab-pane>
                </el-tabs>
                <div class="btn-wrap">
                    <!-- <el-button @click="batchesMigration"  style="margin-right: 30px" type="primary" plain>批量迁移</el-button> -->
                    <el-button :loading="isDowing" @click="derivedForm" type="warning">导出</el-button>
                </div>
            </div>
            <div class="screen-wrap">
                <div class="chage-date">
                    <commonSelect 
                        @changeDate="changeDate"
                        @clickTagDate="clickTagDate"
                    ></commonSelect>
                </div>
                <div class="screen-item">
                    <div class="si-title">归属人</div>
                    <el-select clearable @change="belongerChange" v-model="belonger" placeholder="请选择归属人">
                        <el-option
                        v-for="item in belongerOptions"
                        :key="item.id"
                        :label="item.username"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="screen-item">
                    <div class="si-title">排序</div>
                    <el-select @change="sortChange" v-model="sort" placeholder="请选择归属人">
                        <el-option
                        v-for="item in sortOptions"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="table-wrap">
                <el-table ref="multipleTable" :data="tableData" border style="width: 100%"
                    height="615" @selection-change="handleSelectionChange" v-loading="loading">
                    <el-table-column align="center" type="selection" width="55"></el-table-column>
                    <el-table-column align="center" prop="name" label="访客名称"></el-table-column>
                    <el-table-column align="center" prop="mobile" label="联系方式"></el-table-column>
                    <el-table-column align="center" prop="address" label="来自地区"></el-table-column>
                    <el-table-column align="center" prop="create_timex" label="来访时间"></el-table-column>
                    <el-table-column align="center" prop="ObjectiveName" label="来访事由"></el-table-column>
                    <el-table-column align="center" prop="UserName" label="归属人" >
                        <template slot-scope="scope">
                            <div v-if="scope.row.userList.length>0">
                                <div v-for="(item1,index1) in scope.row.userList" :key="index1">
                                    <el-tag size="medium" style="margin-top:5px">{{item1.username}}</el-tag>
                                </div>
                            </div>
                            <div style="color: #999" v-else>暂无</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="LastRecordTime" label="最后跟进时间">
                        <template slot-scope="scope">
                            <div style="color: #999" v-if="scope.row.LastRecordTime == ''">未跟进</div>
                            <div v-else>{{scope.row.LastRecordTime}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作">
                        <template slot-scope="scope">
                            <el-button v-if="scope.row.LastRecordTime == ''" size="mini" type="info" plain disabled>跟进记录</el-button>
                            <el-button @click="followRecord(scope.row)" v-else size="mini" type="primary">跟进记录</el-button>
                            <el-button @click="Dowingtransfer(scope.row)" size="mini" type="success">分配跟进人</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination-wrap">
                    <el-pagination
                        @current-change="handleCurrentChange"
                        :current-page.sync="currentPage"
                        :page-size="10"
                        layout="total, prev, pager, next"
                        :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <!-- 迁移 -->
        <el-dialog :visible.sync="isAllocation" 
            :before-close="handleClose"
            custom-class="dialog-wrap"
            :close-on-click-modal="false">
            <div class="title">迁移客户</div>
            <div class="content">
                <div class="tag">迁移至：</div>
                <el-select clearable v-model="userId" placeholder="请选择负责人">
                    <el-option
                        v-for="item in belongerOptions"
                        :key="item.id"
                        :label="item.username"
                        :value="item.id">
                        </el-option>
                </el-select>
            </div>
            <div class="btn-wrap">
                <el-button :loading="btnLoading" @click="submitAllocation" type="primary">确定</el-button>
            </div>
        </el-dialog>
        
        <!-- 跟进记录 -->
        <el-dialog :visible.sync="isShowInfo" 
            :before-close="handleClose"
            custom-class="dialog-show-wrap"
            :close-on-click-modal="false">
            <div class="title">跟进记录</div>
            <div class="content">
                <el-table :data="recordData" border style="width: 100%" v-loading="recordLoading" 
                    height="540">
                    <el-table-column align="center" prop="create_timex" label="时间"></el-table-column>
                    <el-table-column align="center" prop="contents" label="跟进内容" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="writeUser.username" label="跟进人" :show-overflow-tooltip="true"></el-table-column>
                </el-table>
                <div class="pagination-wrap">
                    <el-pagination
                        @current-change="recordCurrentChange"
                        :current-page.sync="recordPage"
                        :page-size="10"
                        layout="total, prev, pager, next"
                        :total="recordTotal">
                    </el-pagination>
                </div>
            </div>
        </el-dialog>

        <!-- 分配跟进人 -->
        <el-dialog :visible.sync="transferIsDowing" 
            :before-close="handleClose"
            :close-on-click-modal="false"
            custom-class="dialog-wrap-fp"
            >
            <div class="title">分配跟进人</div>
            <div class="content">
                <el-transfer
                    :filterable="true"
                    filter-placeholder="请输入名称"
                    :filter-method="filterMethod"
                    :titles="['人员列表','当前分配']"
                    v-model="transferSelUids"
                    :data="transferList">
                </el-transfer>
            </div>
            <div class="btn-wrap">
                <el-button :loading="btnLoading" @click="submittransfer" type="primary">确定分配</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import commonSelect from "@/components/commonSelect.vue"
import pageMixin from '@/internal/pageMixin'
import {dealdate} from "@/utils/timeCycle";
import common from "@/utils/common.js"
import axios from "axios";
export default {
    components:{
        commonSelect
    },
    mixins:[pageMixin],
    data () {
        return {
            pageMixin_index:'visitorList',
            activeName: '',
            belongerOptions: [],
            sortOptions: [],
            belonger: '',
            sort: 1,
            objectiveList: [],
            tableData: [],
            objectiveId: '',
            dateType: '',
            startDate: '',
            endDate: '',
            currentPage: 1,
            total: 0,
            loading: false,
            idsList: [],
            isAllocation: false,
            isShowInfo: false,
            userId: '',
            btnLoading: false,
            info: {},
            isDowing: false,
            recordData: [],
            recordPage: 1,
            recordTotal: 0,
            recordLoading: false,

            transferIsDowing:false,
            transferid:'',
            transferList:[],
            transferSelUids:[],
            filterMethod(query, item) {
                return item.username.indexOf(query) > -1;
            }
        }
    },
    mounted () {
        this.getInit();
        this.getVisitorList();
    },
    methods: {
        // 获取列表数据
        getVisitorRecordList(){
            this.recordLoading = true;
            let params = {
                visitor_id: this.info.id,
                page: this.recordPage,
            }
           common.connect('/distributorpcv1/visitor/getVisitorRecordList',params,(res)=>{
                this.recordData = res.data.list;
                this.recordTotal = res.data.count;
                this.recordLoading = false;
            }) 
        },
        recordCurrentChange(val){
            this.recordPage = val;
            this.getVisitorRecordList();
        },
        // 跟进记录
        followRecord(row){
            this.isShowInfo = true;
            this.info = row;
            this.getVisitorRecordList();
        },
         // 分配弹窗
        Dowingtransfer(row){
            
            // console.log(row);
            //来访数据ID
            this.transferid = row.id;
            //人员列表
            // console.log(this.belongerOptions);
            this.transferList=[];
            this.belongerOptions.forEach((user, index) => {
                this.transferList.push({
                    key: user.id,
                    label: user.username,
                    username: user.username,
                    mobile: user.mobile,
                });
            });
            //已分配人员ID数组
            // console.log(row.userList);
            this.transferSelUids=[];
            row.userList.forEach((user, index) => {
                this.transferSelUids.push(user.id);
            });
            // console.log(this.transferSelUids);

            this.transferIsDowing = true;
        },
        // 确定分配
        submittransfer(){
            this.$confirm('是否确定分配操作?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let params = {
                    id: this.transferid,
                    user_ids: JSON.stringify(this.transferSelUids),
                }

                this.btnLoading = true;
                common.connect('/distributorpcv1/visitor/setVisitorBuserList',params,(res)=>{
                    if(res.isSuccess == 1){
                            this.btnLoading = false;
                            this.$message.success(res.msg)
                            this.transferIsDowing = false;
                            this.getVisitorList();
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消分配'
                });
            });

           
        },
        // 确定迁移
        submitAllocation(){
            if(this.userId == ''){
                this.$message.error('请选择归属人');
                return;
            }
            let list = [];
            this.idsList.forEach(v=>{
                list.push(v.id)
            })
            let params = {
                ids: JSON.stringify(list),
                user_id: this.userId
            }
            this.btnLoading = true;
            common.connect('/distributorpcv1/visitor/setVisitorTransfer',params,(res)=>{
               if(res.isSuccess == 1){
                    this.btnLoading = false;
                    this.$message.success(res.msg)
                    this.isAllocation = false;
                    this.getVisitorList();
               }
            })
        },
        // 批量迁移
        batchesMigration(){
            if(this.idsList.length <= 0){
                this.$message.warning('请选择要迁移的客户')
            }else{
                this.isAllocation = true;
            }
        },
        handleClose(){
            this.isAllocation = false;
            this.isShowInfo = false;
            this.transferIsDowing = false;
            this.transferid = '';
        },
        // 多选
        handleSelectionChange(val){
            this.idsList = val;
        },
        // 获取列表数据
        getVisitorList(){
            this.loading = true;
            let params = {
                date_type: this.dateType,
                start_date: this.startDate,
                end_date: this.endDate,
                objective_id: this.objectiveId,
                order: this.sort,
                uid: this.belonger,
                page: this.currentPage
            }
           common.connect('/distributorpcv1/visitor/getVisitorList',params,(res)=>{
                this.tableData = res.data.list;
                this.total = res.data.count;
                this.loading = false;
            }) 
        },
        // 筛选条件
        getInit(){
            common.connect('/distributorpcv1/Visitor/getInit',{},(res)=>{
                this.belongerOptions = res.data.u_list;
                this.sortOptions = res.data.order_list;
                let resultSecond =  res.data.objective_list.map(v =>{
                    return{
                        label: ""+v.id+"",
                        name: v.name
                    }
                })
                this.objectiveList = resultSecond;
                let quan = {
                    name: '全部',
                    label: ''
                }
                this.objectiveList.unshift(quan);

                
            })
        },
        handleClick(tab, event){
            this.objectiveId = tab.name;
            this.currentPage = 1;
            this.getVisitorList();
        },
        sortChange(){
            this.currentPage = 1;
            this.getVisitorList();
        },
        // 归属人筛选
        belongerChange(){
            this.currentPage = 1;
            this.getVisitorList();
        },
        handleCurrentChange(val){
            this.currentPage = val;
            this.getVisitorList();
        },
        // 日期选择
        changeDate(startDate,endDate){
            this.currentPage = 1;
            this.startDate = startDate.slice(0,11)+'00:00:01';
            this.endDate = endDate.slice(0,11)+'23:59:59';
            this.getVisitorList()
        },
        clickTagDate(item){
            this.dateType = item.label;
            this.currentPage = 1;
            if(item.label != 'date'){
                this.startDate = '';
                this.endDate = '';
                this.getVisitorList();
            }
        },
         // 导出
         async derivedForm(){
            let token = localStorage.getItem("token");
            var authorization = 'Bearer ' + token
            if(this.tableData.length == 0){
                this.$message.warning('暂无数据可以导出');
                return
            }
            this.isDowing = true;
            let params = {
                date_type: this.dateType,
                start_date: this.startDate,
                end_date: this.endDate,
                objective_id: this.objectiveId,
                uid: this.belonger,
            }
            let url = common.apiServer+'/distributorpcv1/Visitor/export'
            axios({
                url: url,
                responseType: "blob",
                method: 'POST',
                data: params,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authorization,
                }
            }).then(function(res) {
                let day = dealdate(new Date()).slice(0,10)
                const blob = new Blob([res.data]);
                const fileName = '来访列表数据'+day+'.xls';
                const linkNode = document.createElement('a');
                linkNode.download = fileName; //a标签的download属性规定下载文件的名称
                linkNode.style.display = 'none';
                linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
                document.body.appendChild(linkNode);
                linkNode.click();  //模拟在按钮上的一次鼠标单击
                URL.revokeObjectURL(linkNode.href); // 释放URL 对象
                document.body.removeChild(linkNode);
            })
            setTimeout(()=>{
                this.isDowing = false;
            },2000)
        },
    },
}
</script>

<style lang='scss'>
.visitorList{
    background: rgb(240, 242, 245);
    padding: 10px 25px;
    height: calc(100vh - 80px);
    overflow-y: auto;
    .title-wrap{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin: 5px 0;
        .tag{
            width: 4px;
            height: 20px;
            background: #409EFF;
            border-radius: 10px;
        }
        .title{
            font-size: 18px;
            font-weight: bold;
            padding-left: 10px;
        }
    }
    .case-wrap{
        background: #fff;
        margin-top: 20px;
        border-radius: 6px;
        position: relative;
        .el-tabs__header{
            padding-top: 20px;
            margin: 0 20px;
        }
        .btn-wrap{
            position: absolute;
            right: 20px;
            top: 15px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }
        .screen-wrap{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px;
            .screen-item{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .si-title{
                    font-size: 14px;
                    padding-right: 10px;
                }
            }
        }
        .table-wrap{
            padding: 0 20px 0px 20px;
            .pagination-wrap{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding-top: 6px;
            }
        }
    }
    // 迁移
    .dialog-wrap{
        width: 500px;
        .title{
            display: flex;
            justify-content: center;
            font-size: 17px;
            color: #000;
            font-weight: bold;
            padding-top: 20px;
        }
        .content{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-left: 20px;
            padding: 30px 0;
            .tag{
                margin-right: 10px;
            }
            .el-input{
                width: 390px;
            }
        }
        .btn-wrap{
            display: flex;
            justify-content: flex-end;
            margin-right: 25px;
            padding-bottom: 10px;
        }
    }
    // 分配
    .dialog-wrap-fp{
        width: 800px;
        .title{
            display: flex;
            justify-content: center;
            font-size: 17px;
            color: #000;
            font-weight: bold;
            padding-top: 20px;
        }
        .content{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-around;
            margin-left: 20px;
            padding: 30px 0;
        }
        .btn-wrap{
            display: flex;
            justify-content: flex-end;
            margin-right: 25px;
            padding-bottom: 10px;
        }
    }
    .dialog-show-wrap{
        width: 700px;
        overflow: hidden;
        padding: 20px;
        .title{
            font-size: 17px;
            color: #000;
            font-weight: bold;
            margin-top: -30px;
        }
        .content{
            padding-top: 20px;
            .c-item{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px 0;
                .tit{
                    width: 60px;
                    color: #000;
                    font-size: 15px;
                    text-align: justify;
                    text-align-last: justify;
                }
                .con{
                    width: calc(100% - 80px);
                    color: #666;
                    font-size: 15px;
                }
            }
            .pagination-wrap{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding-top: 6px;
            }
        }
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
    }
}
</style>
